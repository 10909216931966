import * as React from 'react'
import './Coupon.css'
import Coupon from './Coupon'

export default class CouponPreview extends React.Component {
  params = new URLSearchParams(this.props.location.search)

  coupon = {
    forms: {
      location: {
        name: this.params.get('name') || '<name>',
        logo: this.params.get('logo') || 'http://placehold.it/50x50',
        logo_placement: this.params.get('logo_placement'),
        background_color: this.params.get('background_color') || '#F7F7F7',
        button_color: this.params.get('button_color') || '#7EC683',
        button_text_color: this.params.get('button_text_color') || '#FFFFFF',
        coffee_color: this.params.get('coffee_color'),
        coffee_image: this.params.get('coffee_image') || 'http://placehold.it/50x50',
        coupon_image: this.params.get('coupon_image'),
        courtesy_color: this.params.get('courtesy_color'),
        courtesy_text: this.params.get('courtesy_text'),
        free_color: this.params.get('free_color'),
      }
    }
  }

  render() {
    return (<Coupon
      coupon={this.coupon}
      isPreview
    />)
  }
}