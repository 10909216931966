import * as React from 'react'
import './Coupon.css'
import { Redirect } from 'react-router-dom';
import { format } from 'date-fns'
import { connect } from 'react-redux'
import { updateCoupon } from '../actions/coupon'
import { alertDisplay } from '../actions/alert'


const CouponActivate = (props) => {
  

  if (props.invalid === true) {
    return <Redirect to="/not-found" />
  }

  if (props.coupon === null) return <h1>Loading your coupon...</h1>

  // toggle's activation
  const showAlert = () => {
    props.alertDisplay()
  }

  // activates voucher
  const toggleValidation = () => {
    props.alertDisplay()
    props.updateCoupon(props.uuid)
  }

  const renderValidation = () => {
    const courtesyStyle = {
      color: props.coupon.forms.location.courtesy_color
    }
    const colorTrue = {
      backgroundColor: '#F69180', /* #FABE4D */
      borderColor: '#F69180', /* #FABE4D */
      color: props.coupon.forms.location.button_text_color
    }
    const colorFalse = {
      backgroundColor: props.coupon.forms.location.button_color,
      borderColor: props.coupon.forms.location.button_color,
      color: props.coupon.forms.location.button_text_color
    }
    const colorExpired = {
      backgroundColor: '#515151', /* #FF5468 */
      borderColor: '#515151', /* #FF5468 */
      color: props.coupon.forms.location.button_text_color
    }

    if (props.alert === true && props.coupon.used_at === null) {
      return (
        <div>
          <div className='button-container'>
            <button style={colorFalse} onClick={() => toggleValidation()} className='button-validation'>USE NOW</button>
            <button style={colorFalse} onClick={() => showAlert()} className='button-validation'>LATER</button>
          </div>
          <h3 className='limited' style={courtesyStyle}>Use before {format(new Date(props.coupon.expires_at), 'MMMM Do YYYY')}</h3>
          <h3 className='valid' style={courtesyStyle}>Once activated, valid for 10 minutes</h3>
          <div>
            <img className='cup' src={props.coupon.forms.location.coffee_image} alt='Coffee cup' width='131px' />
          </div>
        </div>
      )
    } else if (props.coupon.used_at !== null && new Date(props.coupon.expires_at) > new Date()) {
      return (
        <div>
          <div className='button-container'>
            <button className='button' style={colorTrue}>VOUCHER IN USE</button>
          </div>
          <h3 className='limited' style={courtesyStyle}>Valid for 10 minutes</h3>
          <h3 className='valid' style={courtesyStyle}>Please claim your free coffee / tea!</h3>
          <div>
            <img className='barcode' src={props.coupon.forms.barcode} alt='Barcode' width='167px' />
          </div>
        </div>
      )
    } else if (new Date(props.coupon.expires_at) < new Date()) {
      return (
        <div>
          <div className='button-container'>
            <button className='button' style={colorExpired}>VOUCHER EXPIRED</button>
          </div>
          <h3 className='limited' style={courtesyStyle}>The voucher has now been used</h3>
          <h3 className='valid' style={courtesyStyle}>Enjoy the rest of your day!</h3>
          <div>
            <img className='cup' src={props.coupon.forms.location.coffee_image} alt='Coffee cup' width='131px' />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className='button-container'>
            <button className='button' style={colorFalse} onClick={() => showAlert()}>USE VOUCHER</button>
          </div>
          <h3 className='limited' style={courtesyStyle}>Use before {format(new Date(props.coupon.expires_at), 'MMMM Do YYYY')}</h3>
          <h3 className='valid' style={courtesyStyle}>Once activated, valid for 10 minutes</h3>
          <div>
            <img className='cup' src={props.coupon.forms.location.coffee_image} alt='Coffee cup' width='131px' />
          </div>
        </div>
      )
    }
  }



  const logoStyle = () => {
    if (!props.coupon.forms.location.logo_placement) return
    const styleObj = {}
    const styleStr = props.coupon.forms.location.logo_placement
   
    for (const rule of styleStr.split(';')) {
      const [attrib, value] = rule.split(':')
      styleObj[attrib] = value
    }
 
    return styleObj
  }

  const freeStyle = {
    color: props.coupon.forms.location.free_color
  }

  const coffeeStyle = {
    color: props.coupon.forms.location.coffee_color
  }

  const courtesyStyle = {
    color: props.coupon.forms.location.courtesy_color
  }

  const backgroundColor = props.coupon.forms.location.background_color

  return (
    <div className="container">

      <svg className="coupon-background" width="226" height="436" viewBox="0 0 226 436" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 27.1744C0 12.1664 12.1664 0 27.1743 0H190.22C205.228 0 217.395 12.1664 217.395 27.1743V229.468C216.085 229.516 214.794 229.794 213.581 230.288C212.243 230.835 211.026 231.635 210.002 232.645C208.977 233.654 208.165 234.852 207.61 236.171C207.056 237.489 206.77 238.903 206.77 240.33C206.77 241.758 207.056 243.171 207.61 244.49C208.165 245.808 208.977 247.007 210.002 248.016C211.026 249.025 212.243 249.826 213.581 250.372C214.794 250.867 216.085 251.144 217.395 251.192V400.348C217.395 415.356 205.228 427.522 190.22 427.522H27.1743C12.1663 427.522 0 415.356 0 400.348V251.193C1.30946 251.145 2.60095 250.868 3.81357 250.373C5.15218 249.827 6.36847 249.026 7.393 248.017C8.41752 247.007 9.23022 245.809 9.78469 244.49C10.3392 243.172 10.6245 241.758 10.6245 240.331C10.6245 238.904 10.3392 237.49 9.78469 236.171C9.23022 234.853 8.41752 233.655 7.393 232.645C6.36847 231.636 5.15218 230.835 3.81357 230.289C2.60095 229.794 1.30946 229.517 0 229.469V27.1744Z" fill={backgroundColor} />
        </g>
        <line x1="11" y1="239.547" x2="205.749" y2="239.547" stroke="#8C8C8C" strokeWidth="0.905812" strokeDasharray="13.59 13.59" />
        <defs>
          <filter id="filter0_d" x="0" y="0" width="225.395" height="435.522" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>

      <img style={logoStyle()} src={props.coupon.forms.location.logo} alt="logo" width="100px" />
      <h1 className='free' style={freeStyle}>FREE</h1>
      <h2 className='coffeetext' style={coffeeStyle}>COFFEE / TEA</h2>
      <h3 className='enjoytoday' style={courtesyStyle}><i>{props.coupon.forms.location.courtesy_text}</i></h3>
      {renderValidation()}
    </div>
  )
}

const mapStateToProps = state => ({
  alert: state.alert,
  invalid: state.invalid
})

export default connect(mapStateToProps, { updateCoupon, alertDisplay })(CouponActivate)