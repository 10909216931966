import React, { Component } from 'react';
import CouponActivateContainer from './components/CouponActivateContainer';
import { Route } from 'react-router-dom';
import NotFound from './components/NotFound';
import Redirected from './components/Redirected'
import CouponPreview from './components/CouponPreview'

export default class App extends Component {
  render() {
    return (
      <div className="topbar">
        <Route exact path="/" component={Redirected} />
        <Route exact path="/preview" component={CouponPreview} />
        <Route exact path="/coupon/:uuid" component={CouponActivateContainer} />
        <Route exact path="/not-found" component={NotFound} />
      </div>
    );
  }
}