import * as React from 'react'
import './Coupon.css'

export default class NotFound extends React.Component {
  render(){
    return (
      <div className="not-found" >
        <h1>Coupon not found</h1>
      </div>
    )
  }
}