import React from 'react'
import { connect } from 'react-redux'
import Coupon from './Coupon'
import { fetchCoupon } from '../actions/coupon'
import { clearInterval } from 'timers';

// This components only responsibilty is to fetch the coupon data
class CouponActivateContainer extends React.Component {
  state = {
    expired: false
  }

  isExpired = () => {
    if (this.props.coupon !== null && new Date(this.props.coupon.expires_at) < new Date() && this.state.expired === false) {
      this.setState({
        expired: true
      })
    this.props.fetchCoupon(String(this.props.match.params.uuid))
    }
  }

  componentDidMount() {
    this.props.fetchCoupon(String(this.props.match.params.uuid))
    this.interval = setInterval(() => this.isExpired(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  couponId = this.props.match.params.uuid

  render() {
    return (<Coupon
      coupon={this.props.coupon}
      uuid={this.couponId}
    />)
  }
}

const mapStateToProps = state => ({
  coupon: state.coupon,
  invalid: state.invalid
})

export default connect(mapStateToProps, { fetchCoupon })(CouponActivateContainer)