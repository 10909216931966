import * as request from 'superagent'

const baseUrl = process.env.REACT_APP_API_ENDPOINT

export const FETCH_COUPON = 'FETCH_COUPON'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const INVALID_COUPON = 'INVALID_COUPON'

const getCoupon = coupon => ({
  type: FETCH_COUPON,
  coupon
})

const updateCouponSuccess = coupon => ({
  type: UPDATE_COUPON,
  coupon
})

const invalid = () => ({
  type: INVALID_COUPON,
})

export const fetchCoupon = (couponUuid) => (dispatch) => {

  request
    .get(`${baseUrl}/coupons/${couponUuid}`)
    .then(result => dispatch(getCoupon(result.body)))
    .catch(err => {
      if (err.status !== 404) {
        console.error(err)
      }
      dispatch(invalid())
    })
}

export const updateCoupon = (couponUuid) => (dispatch) => {

  request
    .patch(`${baseUrl}/coupons/${couponUuid}`)
    .send({ couponUuid })
    .then(result => dispatch(updateCouponSuccess(result.body)))
    .catch(err => console.error(err))
}
